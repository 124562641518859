/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import { replaceTopic } from "../app/socketSlice";
import { initSep } from "../data/initData";

const Sep = () => {
  const { client, subTopic } = useSelector((state) => state.socket);
  const [data, setData] = useState(initSep);
  const dispatch = useDispatch();
  useEffect(() => {
    if (subTopic) {
      client.off(subTopic);
    }
    client.on("/sep/status", (msg) => {
      let value = JSON.parse(msg);
      setData(value);
    });
    dispatch(replaceTopic("/sep/status"));
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <Navbar />
        <div className="col-sm p-3 min-vh-100">
          <svg viewBox="0 0 1280 720" xmlns="http://www.w3.org/2000/svg">
            <image href="/images/sep/overview.jpg" width={1280} height={720} x={0} y={0} />
            {data.xTDV1 ? (
              <image
                id="xTDV1"
                href="/images/sep/GnValve.png"
                width={36}
                height={36}
                x={770}
                y={108}
              />
            ) : null}
            {data.xTDV2 ? (
              <image
                id="xTDV2"
                href="/images/sep/GnValve.png"
                width={36}
                height={36}
                x={775}
                y={278}
              />
            ) : null}
            {data.xTDV3 ? (
              <image
                id="xTDV3"
                href="/images/sep/GnValve.png"
                width={36}
                height={36}
                x={774}
                y={445}
              />
            ) : null}
            {data.xINV1 ? (
              <image
                id="xINV1"
                href="/images/sep/GnValve.png"
                width={36}
                height={36}
                x={351}
                y={178}
              />
            ) : null}
            {data.xINV2 ? (
              <image
                id="xINV2"
                href="/images/sep/GnValve.png"
                width={36}
                height={36}
                x={349}
                y={349}
              />
            ) : null}
            {data.xINV3 ? (
              <image
                id="xINV3"
                href="/images/sep/GnValve.png"
                width={36}
                height={36}
                x={352}
                y={515}
              />
            ) : null}
            <text
              id="wmf_uf1"
              
              x={300}
              y={150}
              className="lbtext"
            >
              {data.wmf_uf1}
            </text>
            <text
              id="rinFlow1"
              
              x={280}
              y={172}
              className="lbtext"
            >
              {data.rinFlow1}
            </text>
            <text
              id="rPTin1"
              
              x={415}
              y={222}
              className="lbtext"
            >
              {data.rPTin1}
            </text>
            <text
              id="wmf_uf2"
              
              x={300}
              y={321}
              className="lbtext"
            >
              {data.wmf_uf2}
            </text>
            <text
              id="rinFlow2"
              
              x={280}
              y={342}
              className="lbtext"
            >
              {data.rinFlow2}
            </text>
            <text
              id="rPTin2"
              
              x={415}
              y={392}
              className="lbtext"
            >
              {data.rPTin2}
            </text>
            <text
              id="wmf_uf3"
              
              x={300}
              y={487}
              className="lbtext"
            >
              {data.wmf_uf3}
            </text>
            <text
              id="rinFlow3"
              
              x={280}
              y={507}
              className="lbtext"
            >
              {data.rinFlow3}
            </text>
            <text
              id="rPTin3"
              
              x={415}
              y={557}
              className="lbtext"
            >
              {data.rPTin3}
            </text>
            {data.xBDV1 ? (
              <image
                id="xBDV1"
                href="/images/sep/GnValve.png"
                width={36}
                height={36}
                x={770}
                y={178}
              />
            ) : null}
            {data.xBDV2 ? (
              <image
                id="xBDV2"
                href="/images/sep/GnValve.png"
                width={36}
                height={36}
                x={774}
                y={349}
              />
            ) : null}
            {data.xBDV3 ? (
              <image
                id="xBDV3"
                href="/images/sep/GnValve.png"
                width={36}
                height={36}
                x={773}
                y={516}
              />
            ) : null}
            {data.xBWV1 ? (
              <image
                id="xBWV1"
                href="/images/sep/GnValve90.png"
                width={36}
                height={36}
                x={956}
                y={180}
              />
            ) : null}
            {data.xBWV2 ? (
              <image
                id="xBWV2"
                href="/images/sep/GnValve90.png"
                width={36}
                height={36}
                x={958}
                y={353}
              />
            ) : null}
            {data.xBWV3 ? (
              <image
                id="xBWV3"
                href="/images/sep/GnValve90.png"
                width={36}
                height={36}
                x={957}
                y={481}
              />
            ) : null}
            {data.xPMV1 ? (
              <image
                id="xPMV1"
                href="/images/sep/GnValve.png"
                width={36}
                height={36}
                x={1135}
                y={149}
              />
            ) : null}
            {data.xPMV2 ? (
              <image
                id="xPMV2"
                href="/images/sep/GnValve.png"
                width={36}
                height={36}
                x={1135}
                y={318}
              />
            ) : null}
            {data.xPMV3 ? (
              <image
                id="xPMV3"
                href="/images/sep/GnValve.png"
                width={36}
                height={36}
                x={1137}
                y={444}
              />
            ) : null}
            {data.xBWP1 ? (
              <image
                id="xBWP1"
                href="/images/sep/RunPump.png"
                width={54}
                height={54}
                x={977}
                y={622}
              />
            ) : null}
            {data.xBWP2 ? (
              <image
                id="xBWP2"
                href="/images/sep/RunPump.png"
                width={54}
                height={54}
                x={1037}
                y={622}
              />
            ) : null}
            <text
              id="wmo_uf1"
              
              x={895}
              y={134}
              className="lbtext"
            >
              {data.wmo_uf1}
            </text>
            <text
              id="wmo_uf2"
              
              x={895}
              y={304}
              className="lbtext"
            >
              {data.wmo_uf2}
            </text>
            <text
              id="wmo_uf3"
              
              x={895}
              y={428}
              className="lbtext"
            >
              {data.wmo_uf3}
            </text>
            <text
              id="wmb_uf"
              
              x={895}
              y={587}
              className="lbtext"
            >
              {data.wmb_uf}
            </text>
            <text
              id="rbwFlow"
              
              x={935}
              y={565}
              className="lbtext"
            >
              {data.rbwFlow}
            </text>
            <text
              id="rphUF1"
              
              x={950}
              y={155}
              className="lbtext"
            >
              {data.rphUF1}
            </text>
            <text
              id="rphUF2"
              
              x={950}
              y={325}
              className="lbtext"
            >
              {data.rphUF2}
            </text>
            <text
              id="rphUF3"
              
              x={950}
              y={450}
              className="lbtext"
            >
              {data.rphUF3}
            </text>
            <text
              id="rPTout1"
              
              x={1050}
              y={125}
              className="lbtext"
            >
              {data.rPTout1}
            </text>
            <text
              id="rPTout2"
              
              x={1050}
              y={296}
              className="lbtext"
            >
              {data.rPTout2}
            </text>
            <text
              id="rPTout3"
              
              x={1050}
              y={422}
              className="lbtext"
            >
              {data.rPTout3}
            </text>
            <text
              id="rPTdiff1"
              
              x={610}
              y={213}
              className="lbtext"
            >
              {data.rPTdiff1}
            </text>
            <text
              id="rPTdiff2"
              
              x={615}
              y={388}
              className="lbtext"
            >
              {data.rPTdiff2}
            </text>
            <text
              id="rPTdiff3"
              
              x={611}
              y={551}
              className="lbtext"
            >
              {data.rPTdiff3}
            </text>
            {data.xUFP1 ? (
              <image
                id="xUFP1"
                href="/images/sep/RunPump.png"
                width={56}
                height={56}
                x={43}
                y={573}
              />
            ) : null}
            {data.xUFP2 ? (
              <image
                id="xUFP2"
                href="/images/sep/RunPump.png"
                width={56}
                height={56}
                x={103}
                y={573}
              />
            ) : null}
            {data.xUFP3 ? (
              <image
                id="xUFP3"
                href="/images/sep/RunPump.png"
                width={56}
                height={56}
                x={163}
                y={573}
              />
            ) : null}
            {data.xCME11 ? (
              <image
                id="xCME11"
                href="/images/sep/1.jpg"
                width={16}
                height="16"
                x={1165}
                y={532}
              />
            ) : null}
            {data.xCME12 ? (
              <image
                id="xCME12"
                href="/images/sep/2.jpg"
                width={16}
                height="16"
                x={1187}
                y={532}
              />
            ) : null}
            {data.xCME21 ? (
              <image
                id="xCME21"
                href="/images/sep/1.jpg"
                width={16}
                height="16"
                x={1165}
                y={548}
              />
            ) : null}
            {data.xCME22 ? (
              <image
                id="xCME22"
                href="/images/sep/2.jpg"
                width={16}
                height="16"
                x={1187}
                y={548}
              />
            ) : null}
            {data.xCME31 ? (
              <image
                id="xCME31"
                href="/images/sep/1.jpg"
                width={16}
                height="16"
                x={1165}
                y={566}
              />
            ) : null}
            {data.xCME32 ? (
              <image
                id="xCME32"
                href="/images/sep/2.jpg"
                width={16}
                height="16"
                x={1187}
                y={566}
              />
            ) : null}
            <image
              href="/images/sep/STEP.png"
              x={525}
              y={75}
              width={180}
              height={80}
            />
            <image
              href="/images/sep/air supply.png"
              x={35}
              y={80}
              width={120}
              height={30}
            />
            <image
              href="/images/sep/NTU.png"
              x={165}
              y={338}
              width={70}
              height={35}
            />
            <image
              href="/images/sep/STEP.png"
              x={525}
              y={245}
              width={180}
              height={80}
            />
            <image
              href="/images/sep/STEP.png"
              x={525}
              y={413}
              width={180}
              height={80}
            />
            <image
              href="/images/sep/chem-bg.png"
              x={280}
              y={580}
              width={400}
            />
            <text
              id="uf1st"
              font-size="0.8em"
              x={550}
              y={120}
              className="sttext"
            >
              {data.uf1step}
            </text>
            <text
              id="uf1tm"
              font-size="0.8em"
              x={650}
              y={120}
              className="sttext"
            >
              {data.uf1time}
            </text>
            <text
              id="uf2st"
              font-size="0.8em"
              x={550}
              y={290}
              className="sttext"
            >
              {data.uf2step}
            </text>
            <text
              id="uf2tm"
              font-size="0.8em"
              x={650}
              y={290}
              className="sttext"
            >
              {data.uf2time}
            </text>
            <text
              id="uf3st"
              font-size="0.8em"
              x={550}
              y={458}
              className="sttext"
            >
              {data.uf3step}
            </text>
            <text
              id="uf3tm"
              font-size="0.8em"
              x={650}
              y={458}
              className="sttext"
            >
              {data.uf3time}
            </text>
            <text
              id="rPTAir"
              
              x={115}
              y={99}
              className="lbtext"
            >
              {data.rPTAir}
            </text>
            <text
              id="rTBin"
              
              x={180}
              y={358}
              className="lbtext"
            >
              {data.rTBin}
            </text>
            <text             
              x={405}
              y={614}
              className="lbtext"
            >
              {data.rLV001}
            </text>
            <text             
              x={405}
              y={634}
              className="lbtext"
            >
              {data.rLV002}
            </text>
            <text             
              x={405}
              y={654}
              className="lbtext"
            >
              {data.rLV003}
            </text>
            <text             
              x={405}
              y={674}
              className="lbtext"
            >
              {data.rLV006? data.rLV006: "----"}
            </text>
            <text             
              x={600}
              y={614}
              className="lbtext"
            >
              {data.rLV004}
            </text>
            <text             
              x={600}
              y={634}
              className="lbtext"
            >
              {data.rLV005}
            </text>
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Sep;
