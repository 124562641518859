export const initTaklong = {
  lvpRWP: 89.07497,
  lvcRWP: 1.0689,
  xAirComp: 1,
  rPTAir: 6.0,
  rDiffASF: 0.08057618,
  rPTinASF: 1.98364,
  rPToutASF: 1.903064,
  xAV001ASF: 0,
  xAV002ASF: 0,
  xAV003ASF: 0,
  rTBraw: 115.0531,
  rFLOWraw: 409.7055,
  xAlumrws: 0,
  rFLOWrws: 819.6052,
  xAlumraw: 1,
  xNaOClraw: 1,
  rNaOHlv: 1727.896,
  rH2SO4lv: 1735.71,
  rNaOCllv: 1054.474,
  rAlumlv: 1979.099,
  xrwp1: 1,
  xrwp2: 1,
  xrwp3: 0,
  xrwp4: 0,
  xRetp: 1,
  rFLOWret: 0.013733329,
  xINVuf1: 1,
  xINVuf2: 1,
  xINVuf3: 0,
  xPMVuf1: 1,
  xPMVuf2: 1,
  xPMVuf3: 0,
  xBWVuf1: 0,
  xBWVuf2: 0,
  xBWVuf3: 0,
  xTDVuf1: 0,
  xTDVuf2: 0,
  xTDVuf3: 0,
  xBDVuf1: 0,
  xBDVuf2: 0,
  xBDVuf3: 0,
  rPTdruf1: 0.3941929,
  rPTdruf2: 1.681594,
  rPTdruf3: 0.0,
  rPTinuf1: 1.886811,
  rPTinuf2: 1.901575,
  rPTinuf3: 0.12106299,
  rPToutuf1: 0.2185039,
  rPToutuf2: 0.2081693,
  rPToutuf3: 0.0,
  rpHuf: 7.604576,
  rpHuf1: 8.903296,
  rpHuf2: 8.062745,
  rpHuf3: 7.814714,
  rECuf: 258.8523,
  rFLOWuf1: 174.9882,
  rFLOWuf2: 218.32,
  rFLOWuf3: 0.4429148,
  rFLOWbw: 0.10987334,
  xBWP1: 0,
  xBWP2: 0,
  xNaOHceb: 0,
  xH2SO4ceb: 0,
  xNaOClceb: 1,
  xNaOHcip: 0,
  xH2SO4cip: 0,
  xCIP: 0,
  rUFlv: 1.397806,
  xNaOClpost: 0,
  rFLOWtrans: 0.0,
  rTBtrans: 0.006988048,
  rCltrans: 0.0,
  xTRFP1: 0,
  xTRFP2: 1,
  xTRFP3: 0,
  rLTcws: 0.01,
  rLTrws: 0.01,
  xrwsp1: false,
  xrwsp2: false
};

export const initSep = {
  xTDV1: false,
  xTDV2: false,
  xTDV3: false,
  xINV1: false,
  xINV2: false,
  xINV3: false,
  wmf_uf1: "------.-",
  rinFlow1: "---.-",
  rPTin1: "-.-",
  wmf_uf2: "------.-",
  rinFlow2: 0.1,
  rPTin2: 0.1,
  wmf_uf3: "------.-",
  rinFlow3: 0.1,
  rPTin3: 0.1,
  xBDV1: false,
  xBDV2: false,
  xBDV3: false,
  xBWV1: false,
  xBWV2: false,
  xBWV3: false,
  xPMV1: false,
  xPMV2: false,
  xPMV3: false,
  xBWP1: false,
  xBWP2: false,
  wmo_uf1: 0.1,
  wmo_uf2: 0.1,
  wmo_uf3: 0.1,
  wmb_uf: 0.1,
  rbwFlow: 0.1,
  rphUF1: 0.1,
  rphUF2: 0.1,
  rphUF3: 0.1,
  rPTout1: 0.1,
  rPTout2: 0.1,
  rPTout3: 0.1,
  rPTdiff1: 0.1,
  rPTdiff2: 0.1,
  rPTdiff3: 0.1,
  rTB001: 0.1,
  xUFP1: false,
  xUFP2: false,
  xUFP3: false,
  xCME11: false,
  xCME12: false,
  xCME21: false,
  xCME22: false,
  xCME31: false,
  xCME32: false,
  uf1step: "standby",
  uf2step: "standby",
  uf3step: "standby",
  uf1time: 0,
  uf2time: 0,
  uf3time: 0,
  rPTAir: 0.1,
  rTBin: 0.1,
  rLV001: "----",
  rLV002: "----",
  rLV003: "----",
  rLV004: "----",
  rLV005: "----",
  rLV006: "----",
  clock: "--:--:--"
};
