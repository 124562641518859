/* eslint-disable react-hooks/exhaustive-deps */
import "./App.css";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { loadUser } from "./app/authSlice";
import Auth from "./pages/Auth";
import UnAuth from "./pages/Unauth";
import { useBeforeunload } from "react-beforeunload";
import { clearState, socketAsync } from "./app/socketSlice";

function App() {
  const { user, token } = useSelector((state) => state.auth);
  const { client } = useSelector((state) => state.socket);
  const dispatch = useDispatch();
  useBeforeunload(() => {
    client.disconnect();
    dispatch(clearState());
  });

  useEffect(() => {
    let stock = localStorage.getItem("token");
    if (stock) {
      dispatch(loadUser(stock));
      dispatch(socketAsync({ token }));
    }
  }, [token]);

  return <div className="App">{user ? <Auth /> : <UnAuth />}</div>;
}

export default App;
