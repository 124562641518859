import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import "../css/login.css";
import { signinAsync } from "../app/authSlice.js";
import wiiklogo from "../images/icon192x192.png";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { error } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  return (
    <div className="login">
      <div className="login-div">
        <div className="logo">
          {" "}
          <img
            src={wiiklogo}
            style={{ width: "2.5em" }}
            className="logo-wiik"
            alt="test"
          />{" "}
          &nbsp; Wiik Water Plant{" "}
        </div>
        <Form>
          <div className="fields">
            <div className="username">
              <input
                type="email"
                className="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="email"
              />
            </div>
            <div className="password">
              <input
                type="password"
                className="pass"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="password"
              />
            </div>
          </div>
          <div className="sign-div">
            <button
              className="signin-button"
              onClick={() => dispatch(signinAsync({ email, password }))}
            >
              Login
            </button>
          </div>
        </Form>

        <br />
        {error !== "" ? <span style={{ color: "red" }}>{error}</span> : null}
      </div>
    </div>
  );
};

export default Login;
