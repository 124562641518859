import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./Home";
import Sep from "./Sep";
import Taklong from "./Taklong";

const Auth = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/sep" element={<Sep />} />
      <Route path="/taklong" element={<Taklong />} />
      <Route path="*" element={<Navigate to="/" replace />} />
    </Routes>
  );
};

export default Auth;
