/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "../components/Navbar";
import "../css/scada.css";
import { replaceTopic } from "../app/socketSlice";
import { Link } from "react-router-dom";
import sepplant from '../images/sep/sep2.jpg';
import tkplant from '../images/taklong/cnsplant.jpg';

const Scada = () => {
  const { client, subTopic } = useSelector((state) => state.socket);
  const dispatch = useDispatch();
  useEffect(() => {
    if (subTopic) {
      client.off(subTopic);
    }
    client.on("/plants/status", (msg) => {
      console.log(msg);
    });
    dispatch(replaceTopic("/plants/status"));
  }, []);

  return (
    <div className="container-fluid">
      <div className="row">
        <Navbar />
        <div className="col-sm p-3 min-vh-100">
          <div className="row">
            <div className="col-sm-12 col-md-4 mb-2">
              <div className="card text-dark bg-light text-center">
                <div className="card-body">
                  <h5 className="card-title">สถานีผลิตน้ำประปาสยามอีสเทิร์นฯ</h5>
                  <p className="card-text">
                    กำลังการผลิต 14,000 ลูกบาศก์เมตรต่อวัน
                  </p>
                  <Link to="/sep" >
                    <img className="card-img-bottom" src={sepplant} alt="Sep Logo" id="sepplant"/>
                  </Link>
                </div>
                
              </div>
            </div>
            <div className="col-sm-12 col-md-4 mb-2">
              <div className="card text-dark bg-light text-center">
                <div className="card-body">
                  <h5 className="card-title">สถานีผลิตน้ำประปาชุมนุมทรัพย์</h5>
                  <p className="card-text ">
                  กำลังการผลิต 14,000 ลูกบาศก์เมตรต่อวัน
                  </p>
                  <Link to="/taklong" >
                    <img className="card-img-bottom" src={tkplant} alt="Taklong Plant" id="tkplant"/>
                  </Link>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Scada;
