import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import io from 'socket.io-client';

const initialState = {
    client: null,
    loading: false,
    subTopic: null,
    message: {},
    error: ''
}

export const socketAsync = createAsyncThunk('connectIO', async ({token}, store)=> {
    try {
        const socket = io({
            path: '/socket',
            auth: {
                token: token
              }
        })
        return socket
    } catch (error) { 
        throw error
    }
})

export const socketSlice = createSlice({
    name: 'socket',
    initialState,
    reducers:{
        clearState: (state, action) => {
            return initialState
        },
        replaceTopic: (state, action) => {
            return {...state, subTopic : action.payload}
        },
        addMessage: (state, action)=> {
            return {...state, message: action.payload}
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(socketAsync.pending, (state)=> {
                state.loading = true;
                state.error = '';
            })
            .addCase(socketAsync.fulfilled, (state, action)=> {
                state.loading = false;
                state.error = '';
                state.client = action.payload;
            })
            .addCase(socketAsync.rejected, (state, action)=> {
                state.loading = false;
                state.error = action.error.message;
                state.client = null
            })
    }
})

const socketReducer = socketSlice.reducer

export const {clearState, replaceTopic ,addMessage} = socketSlice.actions

export default socketReducer