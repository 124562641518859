import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Popover } from "antd";
import { signout } from "../app/authSlice";
import sep from "../images/sep.png";
import taklonglogo from "../images/taklong-logo.png";
import { clearState } from "../app/socketSlice";

const Navbar = () => {
  const dispatch = useDispatch();
  const { client } = useSelector((state) => state.socket);

  return (
    <div className="col-sm-auto bg-light sticky-top">
      <div className="d-flex flex-sm-column flex-row flex-nowrap bg-light align-items-center sticky-top">
        
        <ul className="nav nav-pills nav-flush flex-sm-column flex-row flex-nowrap mb-auto mx-auto text-center align-items-center">
          <li className="nav-item">
            <Popover placement="right" title="HomePage">
              <Link
                to="/"
                className="nav-link py-3 px-2 logo-out"
                title=""
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-original-title="Home"
              >
                <i className="bi-house fs-2"></i>
              </Link>
            </Popover>
          </li>
          <li>
            <Popover placement="right" title="Siam Eastern Industrial Park">
              <Link
                to="/sep"
                className="nav-link py-3 px-2"
                title=""
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-original-title="Dashboard"
              >
                <img src={sep} className="logo-bot" alt="test" />
              </Link>
            </Popover>
          </li>
          <li>
            <Popover placement="right" title="โครงการชุมนุมทรัพย์">
              <Link
                to="/taklong"
                className="nav-link py-3 px-2"
                title=""
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-original-title="Dashboard"
              >
                <img src={taklonglogo} className="logo-taklong" alt="test" />
              </Link>
            </Popover>
          </li>

          <li>
            <Popover placement="right" title="ออกจากระบบ">
              <button
                onClick={() => {
                  dispatch(signout());
                  dispatch(clearState());
                  client.disconnect();
                }}
                className="nav-link logo-out py-3 px-2"
                title=""
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-original-title="Customers"
              >
                <i className="bi-box-arrow-right fs-2"></i>
              </button>
            </Popover>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
