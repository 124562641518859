/* eslint-disable react-hooks/exhaustive-deps */
import {useDispatch, useSelector } from "react-redux"
import Navbar from "../components/Navbar"
import { useEffect, useState } from "react"
import { replaceTopic } from "../app/socketSlice"
import {initTaklong} from '../data/initData';

const Taklong = () => {
  const {client, subTopic} = useSelector(state=> state.socket)
  const [data, setData] = useState(initTaklong)
  const [clock, setClock] = useState("--:--:--")
  const [date, setDate] = useState("--/--/----")
  const dispatch = useDispatch()
  useEffect(() => {
    if (subTopic) {
      client.off(subTopic)
    }
    client.on('/taklong/status', (msg)=> {
      let value = JSON.parse(msg)
      let timeval = value.clock
      let clockval = timeval.split(",")
      setDate(clockval[0])
      setClock(clockval[1])
      setData(value)
    })
    dispatch(replaceTopic('/taklong/status'))    
  }, [])

  return (
    <div className="container-fluid">
        <div className="row">
            <Navbar/>
            <div className="col-sm p-3 min-vh-100">
                <svg viewBox="0 0 1280 720" xmlns="http://www.w3.org/2000/svg" >
                  <image href="/images/taklong/tkview.png" width={1280} height={720} x={0} y={0} />
                  <text id="rPTAir" className="lbtk1" x={220} y={104} > {(data.rPTAir).toFixed(1)} </text>
                  <text id="rKW" className="lbtk2" x={240} y={165} > ---.-- </text>
                  <text className="lbtk2" x={200} y={197} > {(data.rDiffASF).toFixed(1)} </text>
                  <text className="lbtk2" x={136} y={225} > {(data.rPTinASF).toFixed(1)} </text>
                  <text className="lbtk2" x={243} y={224} > {(data.rPToutASF).toFixed(1)} </text>
                  <text className="lbtk3" x={212} y={407} > {(data.rTBraw).toFixed(1)} </text>
                  <text className="lbtk3" x={186} y={428} > {(data.rFLOWraw).toFixed(1)} </text>
                  <text className="lbtk2" x={58} y={501} > {(data.rECuf).toFixed(1)} </text>
                  <text className="lbtk2" x={88} y={548} > {(data.rFLOWrws).toFixed(2)} </text>
                  <text className="lbtk2" x={35} y={687} > {(data.rLTrws*100).toFixed(1)} </text>
                  <text className="lbtk2" x={328} y={674} > {(data.lvcRWP*100).toFixed(2)} </text>
                  <text className="lbtk2" x={328} y={690} > {(data.lvpRWP).toFixed(2)} </text>
                  <text className="lbtk2" x={438} y={593} > {(data.rFLOWret).toFixed(2)} </text>
                  <text className="lbtk3" x={433} y={141} > {(data.rPTdruf1).toFixed(2)} </text>
                  <text className="lbtk3" x={433} y={183} > {(data.rPTinuf1).toFixed(2)} </text>
                  <text className="lbtk3" x={428} y={265} > {(data.rPTdruf2).toFixed(2)} </text>
                  <text className="lbtk3" x={428} y={307} > {(data.rPTinuf2).toFixed(2)} </text>
                  <text className="lbtk3" x={433} y={394} > {(data.rPTdruf3).toFixed(2)} </text>
                  <text className="lbtk3" x={433} y={436} > {(data.rPTinuf3).toFixed(2)} </text>
                  <text className="lbtk1" x={573} y={155} > {(data.rPTinuf1 - data.rPToutuf1).toFixed(1)} </text>
                  <text className="lbtk1" x={573} y={279} > {(data.rPTinuf2 - data.rPToutuf2).toFixed(1)} </text>
                  <text className="lbtk1" x={573} y={406.5} > {(data.rPTinuf3 - data.rPToutuf3).toFixed(1)} </text>
                  <text className="lbtk3" x={678} y={108} > {(data.rPToutuf1).toFixed(2)} </text>
                  <text className="lbtk3" x={678} y={236} > {(data.rPToutuf2).toFixed(2)} </text>
                  <text className="lbtk3" x={678} y={364} > {(data.rPToutuf3).toFixed(2)} </text>
                  <text className="lbtk3" x={778} y={108} > {(data.rpHuf1).toFixed(2)} </text>
                  <text className="lbtk3" x={778} y={236} > {(data.rpHuf2).toFixed(2)} </text>
                  <text className="lbtk3" x={778} y={364} > {(data.rpHuf3).toFixed(2)} </text>
                  <text className="lbtk2" x={938} y={96} > {(data.rFLOWuf1).toFixed(1)} </text>
                  <text className="lbtk2" x={923} y={217} > {(data.rFLOWuf2).toFixed(1)} </text>
                  <text className="lbtk2" x={933} y={346} > {(data.rFLOWuf3).toFixed(1)} </text>
                  <text className="lbtk2" x={900} y={454} > {(data.rFLOWbw).toFixed(1)} </text>
                  <text className="lbtk2" x={1008} y={518} > {(data.rFLOWuf1+data.rFLOWuf2+data.rFLOWuf3).toFixed(1)} </text>
                  <text className="lbtk2" x={1140} y={384} > {(data.rECuf).toFixed(1)} </text>
                  <text className="lbtk2" x={1155} y={400} > {(data.rpHuf).toFixed(2)} </text>
                  <text className="lbtk2" x={1030} y={568.5} > {(data.rFLOWtrans).toFixed(1)} </text>
                  <text className="lbtk2" x={1190} y={566} > {(data.rTBtrans).toFixed(2)} </text>
                  <text className="lbtk2" x={1190} y={582} > {(data.rCltrans).toFixed(2)} </text>
                  <text className="lbtk2" x={855} y={622} > {(data.rUFlv*100).toFixed(1)} </text>
                  <text className="lbtk2" x={855} y={642} > {(data.rUFlv/0.0260).toFixed(1)} </text>
                  <text className="lbtk2" x={1175} y={635} > {(data.rLTcws).toFixed(3)} </text>
                  <text className="lbtk2" x={1175} y={655} > {(data.rLTcws/0.04).toFixed(1)} </text>
                  <text className="lbtk3" x={1160} y={42} > {(date)} </text>
                  <text className="lbtk3" x={1160} y={60} > {(clock)} </text>
                  <text className="lbtk3" x={1175} y={139} > {(data.rNaOHlv).toFixed(0)} </text>
                  <text className="lbtk3" x={1175} y={159} > {(data.rH2SO4lv).toFixed(0)} </text>
                  <text className="lbtk3" x={1175} y={182} > {(data.rNaOCllv).toFixed(0)} </text>
                  <text className="lbtk3" x={1175} y={202} > {(data.rAlumlv).toFixed(0)} </text>
                  {data.xrwsp1?(<image id="xrwsp1" href="/images/taklong/gcpump-bg.png" width={47} height={47} x={26} y={593} />):null}
                  {data.xrwsp2?(<image id="xrwsp2" href="/images/taklong/gcpump-bg.png" width={47} height={47} x={70} y={593} />):null}
                  {data.xrwp1?(<image id="xrwp1" href="/images/taklong/gcpump-bg.png" width={47} height={47} x={241} y={594} />):null}
                  {data.xrwp2?(<image id="xrwp2" href="/images/taklong/gcpump-bg.png" width={47} height={47} x={283} y={594} />):null}
                  {data.xrwp3?(<image id="xrwp3" href="/images/taklong/gcpump-bg.png" width={47} height={47} x={325} y={595} />):null}
                  {data.xrwp4?(<image id="xrwp4" href="/images/taklong/gcpump-bg.png" width={47} height={47} x={366} y={595} />):null}
                  {data.xCIP?(<image id="xcip" href="/images/taklong/gcpump-bg.png" width={47} height={47} x={624} y={596} />):null}
                  {data.xBWP1?(<image id="xbwp1" href="/images/taklong/gcpump-bg.png" width={47} height={47} x={842} y={521} />):null}
                  {data.xBWP2?(<image id="xbwp2" href="/images/taklong/gcpump-bg.png" width={47} height={47} x={886} y={521} />):null}
                  {data.xTRFP1?(<image id="xtfp1" href="/images/taklong/gcpump-bg.png" width={47} height={47} x={998} y={614} />):null}
                  {data.xTRFP2?(<image id="xtfp2" href="/images/taklong/gcpump-bg.png" width={47} height={47} x={1041} y={615} />):null}
                  {data.xTRFP3?(<image id="xtfp3" href="/images/taklong/gcpump-bg.png" width={47} height={47} x={1086} y={615} />):null}
                  {data.xRetp?(<image id="xretp" href="/images/taklong/gspump-bg.png" width={35} height={35} x={480} y={658} />):null}
                  {data.xAirComp?(<image id="xairp" href="/images/taklong/greencir-bg.png" width={30} height={30} x={263} y={109} />):null}
                  {data.xAV001ASF?(<image id="xasfv1" href="/images/taklong/gvalve-bg.png" width={34} height={34} x={274} y={237} />):null}
                  {data.xAV002ASF?(<image id="xasfv2" href="/images/taklong/gvalve-bg.png" width={34} height={34} x={276} y={273} />):null}
                  {data.xAV003ASF?(<image id="xasfv3" href="/images/taklong/gvalve-bg.png" width={34} height={34} x={276} y={312} />):null}
                  {data.xINVuf1?(<image id="xinv1" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={392} y={181} />):null}
                  {data.xINVuf2?(<image id="xinv2" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={393} y={305} />):null}
                  {data.xINVuf3?(<image id="xinv3" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={394} y={433} />):null}
                  {data.xTDVuf1?(<image id="xtdv1" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={719} y={140} />):null}
                  {data.xBDVuf1?(<image id="xbdv1" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={721} y={179} />):null}
                  {data.xTDVuf2?(<image id="xtdv2" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={717} y={265} />):null}
                  {data.xBDVuf2?(<image id="xbdv2" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={717} y={306} />):null}
                  {data.xTDVuf3?(<image id="xtdv3" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={719} y={394} />):null}
                  {data.xBDVuf3?(<image id="xbdv3" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={719} y={436} />):null}
                  {data.xPMVuf1?(<image id="xpmv1" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={851} y={109} />):null}
                  {data.xPMVuf2?(<image id="xpmv2" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={850} y={233} />):null}
                  {data.xPMVuf3?(<image id="xpmv3" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={852} y={362} />):null}
                  {data.xBWVuf1?(<image id="xbwv1" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={960} y={166} />):null}
                  {data.xBWVuf2?(<image id="xbwv2" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={958} y={290} />):null}
                  {data.xBWVuf3?(<image id="xbwv3" href="/images/taklong/gvalve-bg.png" width={35} height={35} x={961} y={419} />):null}
                  {data.xAlumrws?(<image id="xalum1" href="/images/taklong/greenrec-bg.png" width={17} height={17} x={189} y={505} />):null}
                  {data.xAlumraw?(<image id="xalum2" href="/images/taklong/greenrec-bg.png" width={17} height={17} x={329} y={515} />):null}
                  {data.xNaOClraw?(<image id="xcl1" href="/images/taklong/greenrec-bg.png" width={17} height={17} x={329} y={532} />):null}
                  {data.xNaOHcip?(<image id="xnaoh2" href="/images/taklong/greenrec-bg.png" width={17} height={17} x={665} y={527} />):null}
                  {data.xH2SO4cip?(<image id="xh2so42" href="/images/taklong/greenrec-bg.png" width={17} height={17} x={665} y={543} />):null}
                  {data.xNaOHceb?(<image id="xnaoh1" href="/images/taklong/greenrec-bg.png" width={17} height={17} x={816.5} y={511} />):null}
                  {data.xH2SO4ceb?(<image id="xh2so41" href="/images/taklong/greenrec-bg.png" width={17} height={17} x={816.5} y={527} />):null}
                  {data.xNaOClpost?(<image id="xnaocl1" href="/images/taklong/greenrec-bg.png" width={17} height={17} x={816.5} y={542} />):null}
                  {data.xNaOClceb?(<image id="xnaocl0" href="/images/taklong/greenrec-bg.png" width={17} height={17} x={1167} y={445} />):null}
                </svg>
            </div>
        </div>
    </div>

  )
}

export default Taklong